<template>
  <div>
    <div style="outline: none; overflow: hidden; width: 1px; height: 1px">
      <input
        type="text"
        ref="dummy"
        style="outline: none; caret-color: transparent"
      />
    </div>

    <v-data-table
      fixed-header
      :height="tableheight"
      :headers="headers"
      :items="dbitems"
      item-key="Id"
      :server-items-length="filtereditems"
      :options.sync="options"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      multi-sort
      :footer-props="footerProps"
      @dblclick.native="handleDblClick"
      ref="refmkstable"
      @mousedown.native="mousedown"
      @mousemove.native="mousemove"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title :dialogid="$el ? $el.__vue__._uid : ''">{{
            tabletitle
          }}</v-toolbar-title>

          <v-spacer></v-spacer>

          <div
            v-if="legend && Array.isArray(legend) && legend.length > 0"
            class="listlegend"
          >
            <template v-if="Object.keys(legend[0]).length > 1">
              <div class="listlegendp1">Exp.:</div>
              <div v-for="(le, index) in legend" v-bind:key="'lc_' + index">
                <div
                  class="legend"
                  :style="'background-color:' + le.color + ';'"
                >
                  {{ le.text }}
                </div>
              </div>
            </template>
            <div v-else v-html="legend[0].text"></div>
          </div>
          <v-spacer></v-spacer>

          <v-tooltip bottom open-delay="700">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn
                  v-model="markedonly"
                  :dark="$vuetify.theme.dark"
                  class="mb-2 mx-2"
                  @click="showonlymarkedrows()"
                  ><v-icon color="primary darken-1">{{
                    markedonly ? "turned_in" : "turned_in_not"
                  }}</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Show only marked Rows</span>
          </v-tooltip>

          <v-tooltip bottom open-delay="700" v-if="hasInitialFilter">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn
                  :dark="$vuetify.theme.dark"
                  class="mb-2 mx-2"
                  @click="resetToInitialFilter()"
                  ><v-icon color="primary darken-1"
                    >settings_backup_restore</v-icon
                  >
                </v-btn>
              </div>
            </template>
            <span>Reset Filter <br />Shortcut: Alt + R</span>
          </v-tooltip>

          <v-tooltip bottom open-delay="700">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn
                  :disabled="!isUserfiltered"
                  :dark="$vuetify.theme.dark"
                  class="mb-2 mx-2"
                  @click="clearFilter()"
                  ><v-icon color="primary darken-1">filter_list</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Clear Filter <br />Shortcut: Alt + C</span>
          </v-tooltip>
          <v-tooltip bottom open-delay="700">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn
                  :disabled="options.sortBy.length === 0"
                  :dark="$vuetify.theme.dark"
                  class="mb-2 mx-2"
                  @click="clearSortOrder()"
                  ><v-icon color="primary darken-1">sort</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Clear Sort Order <br />Shortcut: Alt + O</span>
          </v-tooltip>
          <v-tooltip bottom open-delay="700">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn class="mb-2 mx-2" @click="columnselectorvisible = true">
                  <v-icon color="primary darken-1">view_week</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Select and Arrange your Columns</span>
          </v-tooltip>

          <v-menu offset-y bottom left close-on-click>
            <template #activator="{ on: onMenu }">
              <v-tooltip bottom open-delay="700">
                <template #activator="{ on: onTooltip }">
                  <v-btn
                    :disabled="!queryrights.export"
                    class="mb-2 mx-2"
                    v-on="{ ...onMenu, ...onTooltip }"
                  >
                    <v-icon color="primary darken-1">file_download</v-icon>
                  </v-btn>
                </template>

                <span>Open Export Menu</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item @click="exportXlsx(false)">
                <v-list-item-icon
                  ><v-icon dense>insert_drive_file</v-icon></v-list-item-icon
                >
                <v-list-item-title
                  >Export current
                  {{ isUserfiltered ? "filtered " : "" }}view</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="exportXlsx(true)">
                <v-list-item-icon
                  ><v-icon dense>insert_drive_file</v-icon></v-list-item-icon
                >
                <v-list-item-title>Export complete list</v-list-item-title>
              </v-list-item>
              <v-list-item @click="createMail()">
                <v-list-item-icon
                  ><v-icon dense>mail_outline</v-icon></v-list-item-icon
                >
                <v-list-item-title
                  >Create email with link to this list</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="copyLink()">
                <v-list-item-icon
                  ><v-icon dense>content_copy</v-icon></v-list-item-icon
                >
                <v-list-item-title
                  >Copy list link to clipboard</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>

          <v-tooltip bottom open-delay="700">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn
                  :disabled="!queryrights.write"
                  class="mb-2 mx-2"
                  @click="openNewItemDialog()"
                  ><v-icon color="primary darken-1">add</v-icon></v-btn
                >
              </div>
            </template>
            <span>New Item</span>
          </v-tooltip>

          <v-icon v-if="showCloseSymbol" class="pnt" @click="emitClose"
            >mdi-close</v-icon
          >
        </v-toolbar>
      </template>

      <template v-slot:item="props">
        <tr
          :class="
            props.item.isSelected
              ? 'text-start v-data-table__selected'
              : 'text-start'
          "
          :itemid="props.item[identifierItemCase]"
          :lnk="lnkValue('tr', identifierItemCase, props.item)"
          :style="gettrstyle(props.item)"
          @click="selectRow($event)"
        >
          <td v-if="hasHistory" @click="openHistory($event.target)">
            <template v-if="props.item['HID'] > 0">
              <v-badge
                v-if="ShowHistoryCount"
                color="accent"
                :content="props.item['HID']"
                overlap
                :dark="$vuetify.theme.dark"
              >
                <v-icon>history</v-icon>
              </v-badge>
              <v-icon v-else>history</v-icon>
            </template>
          </td>

          <td v-if="actions.length > 0">
            <v-checkbox
              class="pa-2"
              v-if="props.item.markable"
              dense
              v-model="props.item.mark"
              @change="setMarkedItems()"
            ></v-checkbox>
          </td>

          <template v-for="(visibleProp, index) of visibleProps">
            <td
              :key="index"
              v-if="
                visibleProp.type === 'String' &&
                visibleProp.value.indexOf('mail') > -1 &&
                props.item[visibleProp.value] &&
                props.item[visibleProp.value].indexOf &&
                props.item[visibleProp.value].indexOf('@') > -1
              "
            >
              <a :href="'mailto:' + props.item[visibleProp.value]">{{
                props.item[visibleProp.value]
              }}</a>
            </td>

            <td
              :key="index"
              v-else-if="visibleProp.value === identifier"
              class="tdright"
              style="width: 52px"
            >
              {{ props.item[visibleProp.value] }}
            </td>
            <td :key="index" v-else-if="visibleProp.type == 'Boolean'">
              <v-icon v-if="props.item[visibleProp.value]">check</v-icon>
            </td>
            <td
              :key="index"
              v-else-if="visibleProp.value === 'Remarks'"
              v-html="
                highlight(
                  props.item[visibleProp.value],
                  searchItem[visibleProp.value.toLowerCase()]
                )
              "
            ></td>
            <td
              :key="index"
              v-else-if="
                visibleProp.type === 'String' &&
                props.item[visibleProp.value] !== null &&
                props.item[visibleProp.value]
                  .toLowerCase()
                  .startsWith('https://')
              "
              :inner-html.prop="
                '<a target=\'_blank\' href=\'' +
                props.item[visibleProp.value] +
                '\'>' +
                highlight(
                  props.item[visibleProp.value],
                  searchItem[visibleProp.value.toLowerCase()]
                ) +
                '</a>'
              "
            ></td>
            <!-- <td
              :key="index"
              v-else-if="
                visibleProp.value === 'Position' && visibleProp.type === 'Int32'
              "
            >
              <v-btn
                color="success"
                icon
                @click="move(props.item[identifierItemCase])"
              >
                <v-icon>mdi-arrow-up</v-icon> </v-btn
              ><v-btn
                color="success"
                icon
                @click="move(props.item[identifierItemCase], true)"
              >
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </td> -->
            <td
              :key="index"
              v-else
              :inner-html.prop="
                highlight(
                  props.item[visibleProp.value],
                  searchItem[visibleProp.value.toLowerCase()]
                ) +
                (visibleProp.isphonenr &&
                props.item[visibleProp.value] !== null &&
                props.item[visibleProp.value] !== 'dummy'
                  ? '&nbsp;&nbsp;<a href=\'tel:' +
                    props.item[visibleProp.value] +
                    '\'><i class=\'material-icons\' style=\'font-size: small;\'>call</i></a>'
                  : '') +
                (visibleProp.xmpp && props.item[accountFieldName]
                  ? '&nbsp;&nbsp;<a title=\'start chat\' href=\'xmpp:' +
                    props.item[accountFieldName] +
                    '@tlabs.de\'><i class=\'material-icons\' style=\'font-size: small;\'>chat_bubble_outline</i></a>'
                  : '')
              "
              :lnk="lnkValue('td', visibleProp.value, props.item)"
              :class="[
                lnkValue('td', visibleProp.value, props.item) !== ''
                  ? 'lnk'
                  : visibleProp.rightalign
                  ? 'tdright'
                  : '',
              ]"
              :style="
                visibleProp.isphonenr || visibleProp.xmpp
                  ? 'white-space:nowrap;'
                  : ''
              "
              @click="
                [
                  lnkValue('td', visibleProp.value, props.item) !== ''
                    ? handleClick($event)
                    : null,
                ]
              "
            ></td>
          </template>
        </tr>
      </template>

      <template v-slot:header.markable="{ header }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>rule</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item> visible items only </v-list-item>
            <v-list-item link @click="mark_select_all" class="indented-item">
              select all
            </v-list-item>
            <v-list-item
              link
              @click="mark_select_none(false)"
              class="indented-item"
              >select none</v-list-item
            >
            <v-list-item
              link
              @click="mark_invert_selection"
              class="indented-item"
              >invert selection</v-list-item
            >
            <v-divider></v-divider>
            <v-list-item>all items</v-list-item>
            <v-list-item
              link
              @click="mark_select_none(true)"
              class="indented-item"
              >select none</v-list-item
            >
            <v-divider></v-divider>
            <v-list-item>actions</v-list-item>
            <v-tooltip
              right
              v-for="(action, index) in actions"
              :key="'act_' + index"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  link
                  v-bind="attrs"
                  v-on="on"
                  @click="fireActionPrep(action)"
                >
                  <v-list-item-avatar>
                    <v-icon
                      :color="$vuetify.theme.dark ? 'white' : 'black'"
                      dark
                      v-text="action.ActionIcon"
                    ></v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      action.ActionName
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon> </v-list-item-icon>
                </v-list-item>
              </template>
              <span v-html="action.ActionDescriptionHtml"></span>
            </v-tooltip>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:header="{ props }" v-if="showFilterHeader">
        <tr class="dmyfilterheader">
          <th
            v-for="(header, index) in props.headers"
            :key="header.text"
            @click="focusFilter(index)"
          >
            <input
              v-if="header.value !== 'markable'"
              :style="!(index === 0 && hasHistory) ? '' : { display: 'none' }"
              type="text"
              :class="'mksfilter' + ($vuetify.theme.dark ? '_dark' : '')"
              dense
              :ref="'s_header_' + index"
              v-bind:key="'ff_' + header.value"
              v-model="searchItem[header.value.toLowerCase()]"
              placeholder="filter here"
              :fi="index"
              @change="start"
              @focus="setFilterRowFocus"
            />
            <template v-else>
              <v-tooltip right open-delay="700">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="cycleFilterState"
                  >
                    <v-icon>{{ markFilterIcons[markFilterState] }}</v-icon>
                  </v-btn>
                </template>
                <span
                  v-html="
                    'Cycle through filter states: all -> marked -> not marked'
                  "
                ></span>
              </v-tooltip>
            </template>
          </th>
        </tr>
      </template>

      <template v-slot:footer.page-text="items">
        {{ items.pageStart }} - {{ items.pageStop }} of {{ items.itemsLength }}
        {{
          items.itemsLength !== totalitems
            ? " (from " + totalitems + " total)"
            : ""
        }}
      </template>
    </v-data-table>

    <v-dialog
      content-class="v-dialog-mks"
      v-model="showActionConfirm"
      scrollable
      v-bind:retain-focus="true"
      persistent
      no-click-animation
    >
      <v-card>
        <v-card-title
          >Warning
          <v-spacer></v-spacer>
          <v-icon class="pnt" @click="showActionConfirm = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <span
            class="mksheadline"
            :dialogid="$el ? $el.__vue__._uid : ''"
            v-html="
              'You are about to execute an action on <b>' +
              this.actionIDs.length +
              ' items</b>. Are you sure?'
            "
          >
          </span>
        </v-card-text>
        <v-card-actions
          ><v-spacer></v-spacer>
          <v-btn
            color="warning"
            class="mr-4"
            @click="
              fireAction();
              showActionConfirm = false;
            "
          >
            <v-icon left>directions_run</v-icon>execute
          </v-btn>

          <v-btn color="primary" class="mr-4" @click="showActionConfirm = false"
            >cancel</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="v-dialog-mks"
      v-model="showClearItemsOffer"
      scrollable
      v-bind:retain-focus="true"
      persistent
      no-click-animation
    >
      <v-card>
        <v-card-title
          >Success!
          <v-spacer></v-spacer>
          <v-icon class="pnt" @click="closeClearItemsDialog(false)"
            >mdi-close</v-icon
          >
        </v-card-title>

        <v-card-actions
          ><v-spacer></v-spacer>
          <v-btn
            color="accent"
            class="mr-4"
            @click="closeClearItemsDialog(true)"
          >
            <v-icon left>check_box</v-icon>clear marked items
          </v-btn>

          <v-btn
            color="primary"
            class="mr-4"
            @click="closeClearItemsDialog(false)"
            >close</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>

    <mksdetails
      :editquery="editlnk"
      :visible="details"
      :idfilter="idfilter"
      @close="closeDetails"
      @navigate="navigate"
      @reopen="reopen"
    ></mksdetails>
    <mksresourcebundle
      v-if="resourcebundle"
      :editquery="editlnk"
      @close="closeResourcebundle"
    >
    </mksresourcebundle>
    <mkscolumns
      :visible="columnselectorvisible"
      :visibleProps="visibleProps"
      :id_query="id_query"
      @close="closeColumnsSelector"
    ></mkscolumns>
    <mkshistory
      :visible="historyVisible"
      :editquery="editlnk"
      @close="closeHistory"
    ></mkshistory>
    <mksfastjumpdialog
      :visible="showFastJumpDialog"
      :properties="jumpheaders"
      :title="'Jump to Filter:'"
      @close="jumpToFilter"
    ></mksfastjumpdialog>
  </div>
</template>

<style>
/* tr.text-start {
  white-space: nowrap !important;
} */

td.lnk {
  font-weight: bold;
  cursor: pointer;
}

td.tdright {
  text-align: right;
}
</style>

<style scoped>
.indented-item {
  padding-left: 40px; /* Adjust for desired indentation */
}

tr.dmyfilterheader > th {
  height: 32px !important;
}

/* ::v-deep tr.text-start {
  vertical-align: top;
}

::v-deep tr.text-start td {
  padding-top: 0.375em !important;
} */

.truncate {
  max-width: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep .v-data-table-header {
  white-space: nowrap;
}

::v-deep .lighten-3 {
  background-color: #8ac5ff;
  border-color: #8ac5ff;
}

::v-deep tr.primary {
  background-image: linear-gradient(
    0deg,
    rgba(125, 146, 245, 0.5) 0%,
    rgba(125, 146, 245, 0.1) 10%,
    rgba(125, 146, 245, 0.1) 90%,
    rgba(125, 146, 245, 0.5) 100%
  ) !important;
}

::v-deep tr.v-data-table__selected {
  background-image: linear-gradient(
    0deg,
    rgba(125, 146, 245, 0.5) 0%,
    rgba(125, 146, 245, 0.2) 10%,
    rgba(125, 146, 245, 0.2) 90%,
    rgba(125, 146, 245, 0.5) 100%
  ) !important;
}

input.mksfilter {
  outline: 0;
  border-width: 0 0 2px;
  border-color: blue;
  color: rgba(0, 0, 0, 0.87);
}

input.mksfilter:focus {
  background-color: #e8eaf6;
}

input.mksfilter::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: darkgrey;
  opacity: 0.5;
  /* Firefox */
}

input.mksfilter::placeholder:hover {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: darkgrey;
  opacity: 1;
  /* Firefox */
}

input.mksfilter_dark {
  outline: 0;
  border-width: 0 0 2px;
  border-color: blue;
  color: #fff;
}

input.mksfilter_dark:focus {
  background-color: #000000;
}

input.mksfilter_dark::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: darkgrey;
  opacity: 0.5;
  /* Firefox */
}

input.mksfilter_dark::placeholder:hover {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: darkgrey;
  opacity: 1;
  /* Firefox */
}

div.listlegend {
  background-color: #fafafa;
  padding: 3px;
  border-radius: 3px;
}

div.listlegendp1 {
  font-size: 12px;
  float: left;
  margin: 0px 5px 0px 5px;
}

div.legend {
  font-size: 12px;
  padding: 0px 5px 0px 5px;
  margin: 0px 3px 0px 3px;
  float: left;
  /* border: 1px solid grey; */
}
</style>

<script>
import { mapState } from "vuex";
import mksdetails from "@/components/mksdetails.vue";
import mksresourcebundle from "@/components/mksresourcebundle.vue";

import mkscolumns from "@/components/mkscolumns.vue";
import mkshistory from "@/components/mkshistory.vue";
import mksfastjumpdialog from "@/components/mksfastjumpdialog.vue";

export default {
  components: {
    mksdetails,
    mksresourcebundle,
    mkscolumns,
    mkshistory,
    mksfastjumpdialog,
  },

  name: "mkstable",

  props: {
    id_query: {
      type: Number,
      required: true,
      default: 0,
    },

    idfilter: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    showCloseSymbol: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    markFilterState: 0,
    markFilterIcons: [
      "check_box_outline_blank", // All items
      "check_box", // Active items
      "indeterminate_check_box", // Inactive items
    ],
    markFilterOptions: [
      { label: "All", filter: () => true },
      { label: "Active", filter: (item) => item.active },
      { label: "Inactive", filter: (item) => !item.active },
    ],
    id_old_query: 0,
    action: {},
    actionIDs: [],
    showActionConfirm: false,
    showClearItemsOffer: false,
    details: false,
    resourcebundle: false,
    markedonly: false,
    selectedItemNumber: 0,
    selected: [],
    oldSelectedItemNumber: -1,
    ignoreKeyStrokes: false,
    headers: [],
    // editheaders: [],
    dbitems: [],
    actions: [],
    queryrights: {},
    totalitems: 0,
    filtereditems: 0,
    filter: {},
    currentQueryInfo: {},
    currentQueryLinksInfo: {},
    identifier: "",
    identifierItemCase: "",
    editlnk: {},
    // editedIndex: -1,
    searchItem: {},
    dialogTitle: "",
    dialogMode: 0,
    defaultItem: {},
    loading: false,
    optionsChangedIgnore: false,
    errors: [],
    tabletitle: "",
    tableheight: "500px",
    baseheight: null,
    container: null,
    myerror: "",
    page: 1,
    pageCount: 0,
    optionsLength: 6,
    footerProps: {
      "items-per-page-options": [100],
      showFirstLastPage: true,
    },
    options: {
      itemsPerPage: 100,
    },
    runningquery: false,
    visibleProps: [],
    hasHistory: false,
    linkProps: [],

    columnselectorvisible: false,
    historyVisible: false,
    // selectedItemNumber: 0,
    showFilterHeader: false,
    lastFocusedFilter: null,
    msdwn: 0,
    msmv: 0,
    pagingInfo: [
      { key: 36, btn: "First" },
      { key: 35, btn: "Last" },
      { key: 33, btn: "Previous" },
      { key: 34, btn: "Next" },
    ],
    queryChanged: true,
    hasInitialFilter: false,
    ShowHistoryCount: false,
    ShowIdentifierColumn: false,
    grphsh: "",
    legend: [],
    classnameforrow: "",
    accountFieldName: "",
    showFastJumpDialog: false,
    stillFocusingFastJump: false,
  }),
  created() {
    this.$changesHub.$on("Changes", this.Changes);
    this.refreshSettings(false);

    var urlparams = this.$getUrlParams();

    if (!isNaN(urlparams.l) && !isNaN(urlparams.v)) {
      this.searchItem[
        this.currentUser.allowedQueries.find(
          (x) => x.id === this.id_query
        ).identifier
      ] = urlparams.v;
    } else if (urlparams.f && urlparams.v) {
      this.searchItem[urlparams.f.toLowerCase()] = urlparams.v;
    }

    this.accountFieldName =
      process.env.VUE_APP_TITLE === "HomeDB2.0" ||
      (this.currentUser.webname !== null &&
        (this.currentUser.webname.indexOf("HomeDB") > -1 ||
          this.currentUser.webname.indexOf("TLabs_Data") > -1))
        ? "ADS_User"
        : "Useraccount";
  },
  beforeDestroy() {
    this.$changesHub.$off("Changes", this.Changes);
  },
  watch: {
    prefs: {
      handler() {
        this.refreshSettings();
      },
      deep: true,
    },
    options: {
      handler() {
        if (!this.optionsChangedIgnore) {
          this.getdata(true);
        }
      },
      deep: true,
    },
    id_query: {
      handler() {
        this.setMarkedItems();
        this.ignoreKeyStrokes = true;
        this.lastFocusedFilter = null;
        this.queryChanged = true;
        this.selectedItemNumber = 0;
        this.tabletitle = "Loading ...";
        this.legend = [];

        this.dbitems = [];
        this.headers = [];

        this.$nextTick(() => {
          this.refreshdata();
        });
      },
    },
    idfilter: {
      handler() {
        this.ignoreKeyStrokes = true;
        this.lastFocusedFilter = null;
        this.queryChanged = true;
        this.selectedItemNumber = 0;
        this.refreshdata();
      },
      deep: true,
    },
    lastKeyUp: {
      handler() {
        // if (this.currentDialog || this.details || this.showFastJumpDialog) {
        //   return;
        // }

        if (this.ignoreKeyStrokes) {
          this.ignoreKeyStrokes = false;
          return;
        }
        var passed = false;
        var dia2 = this.$getHighest(".v-dialog--active");
        passed = dia2 === null;
        if (!passed) {
          dia2 = dia2 && dia2.querySelector("[dialogid]");
          passed =
            dia2 &&
            parseInt(dia2.getAttribute("dialogid")) === this.$el.__vue__._uid;
        }
        if (passed) {
          if (this.lastKeyUp.altKey) {
            switch (this.lastKeyUp.keyCode) {
              case 82: // Alt + R = Reset to initial Filter
                this.resetToInitialFilter();
                break;
              case 79: // Alt + O = Remove alle Sorting
                this.lastKeyUp.preventDefault();
                this.clearSortOrder();
                break;
              case 74: // Alt + J = Show Field List for Fast Jump
                this.showFastJumpDialog = true;

                break;
              case 67: // Alt + C = clear filter
                this.lastKeyUp.preventDefault();
                this.clearFilter();
                break;
              case 87: // Alt + W = Focus filter
                this.lastKeyUp.preventDefault();

                this.$el
                  .querySelectorAll("input[fi]")
                  [this.hasHistory ? 1 : 0].focus(); //eslint-disable-line
                break;
            }
          } else {
            switch (this.lastKeyUp.keyCode) {
              case 36: // Pos1 in ListMode
                if (!this.lastKeyUp.target.hasAttribute("fi")) {
                  this.selectedItemNumber = 0;
                  this.highlightRow(true);
                }
                break;
              case 35: // End in ListMode
                if (!this.lastKeyUp.target.hasAttribute("fi")) {
                  // this.selectedItemNumber = 0;
                  this.selectedItemNumber = this.dbitems.length - 1;
                  this.highlightRow(true);
                }
                break;
              case 33: // Page Up in Listmode
              case 34: // Page Down in Listmode
                if (!this.details) {
                  var btnUp = this.$el.querySelectorAll(
                    "button.v-btn[aria-label='" +
                      this.pagingInfo.find(
                        (x) => x.key === this.lastKeyUp.keyCode
                      ).btn +
                      " page']"
                  );
                  if (btnUp.length === 1) {
                    this.$refs.dummy.focus();
                    this.selectedItemNumber = 0;
                    btnUp[0].click();
                  }
                }
                break;
              case 38: // Arrow Up in Listmode
                if (this.lastKeyUp.target.placeholder !== "List Name") {
                  if (this.selectedItemNumber > 0) {
                    this.selectedItemNumber--;

                    this.highlightRow(true, -1);
                  } else {
                    var tmp = this.$el.querySelector(
                      "input[fi='" + (this.hasHistory ? 1 : 0) + "']"
                    );
                    if (tmp) {
                      tmp.focus();
                    }

                    this.$nextTick(function () {
                      this.$el
                        .querySelectorAll("tr[itemid]")
                        .forEach((el) =>
                          el.classList.remove("primary", this.classnameforrow)
                        );
                    });
                  }
                }
                break;
              case 40: // Arrow Down in Listmode
                if (this.lastKeyUp.target.placeholder !== "List Name") {
                  if (this.lastKeyUp.target.hasAttribute("fi")) {
                    this.lastKeyUp.target.blur();
                    this.selectedItemNumber = -1;
                  } else {
                    if (this.selectedItemNumber < 0) {
                      this.selectedItemNumber = 0;
                    }
                  }

                  if (this.selectedItemNumber < this.dbitems.length - 1) {
                    this.selectedItemNumber++;
                    this.highlightRow(true, 1);
                  }
                }
                break;

              case 13:
                if (
                  !this.details &&
                  !this.showFastJumpDialog &&
                  !this.stillFocusingFastJump
                ) {
                  if (!this.lastKeyUp.target.hasAttribute("fi")) {
                    var rows = this.$el.querySelectorAll("tr.text-start");
                    if (rows.length > 0) {
                      if (
                        this.selectedItemNumber > -1 &&
                        rows[this.selectedItemNumber].getAttribute("lnk")
                      ) {
                        this.openEditDialog(
                          rows[this.selectedItemNumber].getAttribute("lnk")
                        );
                      } else {
                        // open first element

                        this.openEditDialog(rows[0].getAttribute("lnk"));
                      }
                    }
                  } else {
                    this.options["page"] = 1;
                    this.selectedItemNumber = 0;
                    this.getdata();
                  }
                }

                break;
            }
          }
        }
      },
    },
  },

  updated: function () {},

  mounted() {
    this.container = this.$el.closest(".v-card__text");
    if (this.container === null) {
      this.container = document.getElementsByClassName(
        "container fill-height container--fluid"
      )[0];
    }

    this.$nextTick(function () {
      // zeitverzögerte Ausführung um richtige Höhe für container zu erhalten
      this.refreshBaseHeight();
      window.addEventListener("resize", this.refreshBaseHeight);
    });
  },
  computed: {
    ...mapState(["currentUser", "currentDialog", "lastKeyUp"]),

    jumpheaders: {
      get() {
        return this.headers.map((header) => ({
          a: header.text,
          n: header.value,
        }));
      },
    },

    isfiltered: {
      get() {
        return !(
          Object.keys(this.idfilter).length === 0 &&
          this.idfilter.constructor === Object
        );
      },
    },

    isUserfiltered: {
      get() {
        return !(
          Object.keys(this.searchItem).length === 0 &&
          this.searchItem.constructor === Object &&
          Object.keys(this.filter).length === 0 &&
          this.filter.constructor === Object &&
          this.markFilterState === 0
        );
      },
    },

    hasPrevious: {
      get() {
        return this.selectedItemNumber > 0;
      },
    },
    hasNext: {
      get() {
        return this.selectedItemNumber + 1 < this.dbitems.length;
      },
    },
    prefs() {
      return this.currentUser.prefs;
    },
  },

  methods: {
    cycleFilterState() {
      if (this.loading) {
        this.$showMessage({
          content: "Please wait - data is still loading",
          color: "warning",
        });
        return;
      }
      // Cycle through 0, 1, and 2
      this.markFilterState = (this.markFilterState + 1) % 3;
      this.getdata();
    },
    clearMarkedItems() {
      this.$clearMarkedItems(
        this.id_old_query === 0 ? this.id_query : this.id_old_query
      );
      this.id_old_query = this.id_query;
      this.dbitems.forEach((item) => {
        item.mark = false;
      });
    },
    setMarkedItems() {
      this.$setMarkedItems({
        id_query: this.id_old_query === 0 ? this.id_query : this.id_old_query,
        ids: this.dbitems.map((item) => {
          return { id: item[this.identifierItemCase], marked: item.mark };
        }),
      });
      this.id_old_query = this.id_query;
    },
    mark_select_all() {
      this.dbitems.forEach((item) => {
        item.mark = true;
      });
      this.dbitems = [...this.dbitems];
      this.setMarkedItems();
    },
    mark_select_none(clearAllItems) {
      this.dbitems.forEach((item) => {
        item.mark = false;
      });
      this.dbitems = [...this.dbitems];
      if (clearAllItems) {
        this.clearMarkedItems();
      }
      this.setMarkedItems();
    },
    mark_invert_selection() {
      this.dbitems.forEach((item) => {
        item.mark = !item.mark;
      });
      this.dbitems = [...this.dbitems];
      this.setMarkedItems();
    },
    fireActionPrep(action) {
      this.action = action;

      this.setMarkedItems();

      this.actionIDs = this.$store.getters.getMarkedItems(this.id_query);

      // this.actionIDs = this.dbitems
      //   .filter((item) => item.mark)
      //   .map((item) => Number(item[this.identifierItemCase]));

      if (this.actionIDs.length === 0) {
        this.$showMessage({ content: "No items selected", color: "info" });
        return;
      }

      if (this.actionIDs.length > 10) {
        this.showActionConfirm = true;
        return;
      } else {
        this.fireAction();
      }
    },
    fireAction() {
      var lnk = this.currentQueryLinksInfo.find(
        (lnk) => lnk.ID_Feldname.toLowerCase() === this.identifier.toLowerCase()
      );

      var actionQuery = {
        tablename: this.currentQueryInfo.Bezeichnung,
        id_query: this.id_query,
        id_link: lnk.ID_Link,
        id: 0,
        identifier: this.identifier,
      };

      this.$http
        .post("api/queryaction", {
          query: actionQuery,
          action: this.action.ActionName,
          ids: this.actionIDs,
        })
        .then(() => {
          this.showClearItemsOffer = true;
          // this.clearMarkedItems();
          // this.getdata(false);
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeClearItemsDialog(clearAllItems) {
      if (clearAllItems) {
        this.mark_select_none(true);
        
      }
      this.showClearItemsOffer = false;
      this.getdata(false);
    },
    setFilterRowFocus() {
      this.$nextTick(function () {
        this.$el
          .querySelectorAll("tr[itemid]")
          .forEach((el) =>
            el.classList.remove("primary", this.classnameforrow)
          );
      });
    },

    tablelink() {
      return (
        window.location.href.slice(0, -1) +
        "?sl=" +
        Buffer.from("?q=" + this.id_query).toString("base64")
      );
    },
    createMail() {
      window.location.href =
        "mailto:?subject=" +
        encodeURIComponent(this.tabletitle) +
        "&body=" +
        encodeURIComponent("Link to List:\r\n") +
        this.tablelink(); //eslint-disable-line
    },
    copyLink() {
      navigator.clipboard.writeText(this.tablelink());
      this.$showMessage({
        content: "Link copied to clipboard",
        color: "success",
        timeout: 1500,
      });
    },
    showonlymarkedrows() {
      this.markedonly = !this.markedonly;
      if (this.options["page"] !== 1) {
        this.options["page"] = 1;
      } else {
        this.getdata(true);
      }
    },
    focusFilter(index) {
      var tempthis = this;
      this.$nextTick(() => {
        if (
          this.$refs["s_header_" + index] &&
          this.$refs["s_header_" + index][0] &&
          this.$refs["s_header_" + index][0].focus
        ) {
          this.$refs["s_header_" + index][0].focus();
          window.setTimeout(function () {
            tempthis.stillFocusingFastJump = false;
          }, 500);
        }
      });
    },
    clearFilter() {
      this.ignoreKeyStrokes = true;
      this.lastFocusedFilter = null;
      this.options["page"] = 1;
      this.selectedItemNumber = 0;
      this.markFilterState = 0;

      this.searchItem = {};
      this.getdata(true, true);
    },
    clearSortOrder() {
      this.ignoreKeyStrokes = true;
      this.lastFocusedFilter = null;
      this.options["page"] = 1;
      this.options["sortBy"] = [];
      this.options["sortDesc"] = [];
      this.selectedItemNumber = 0;
      this.getdata(true, true);
    },
    resetToInitialFilter() {
      this.ignoreKeyStrokes = true;
      this.lastFocusedFilter = null;
      this.queryChanged = true;
      this.options["page"] = 1;
      this.selectedItemNumber = 0;

      this.searchItem = { resetfilter: "1" };
      this.getdata(true, true);
    },
    move(itemid, down) {
      var itemindex = this.getItemIndexFromId(itemid);

      var f = this.dbitems.splice(itemindex, 1)[0];
      this.dbitems.splice(itemindex + (down ? 1 : -1), 0, f);

      // this.$showMessage({
      //   content: itemid + " " + (down ? "down" : "up"),
      //   color: "info",
      // });
    },
    selectRow(evt) {
      var handleRowNumber = evt.target.parentElement.rowIndex - 2;

      if (evt.ctrlKey) {
        if (this.dbitems[handleRowNumber].isSelected) {
          this.$el
            .querySelector(
              "tr[itemid='" +
                this.dbitems[handleRowNumber][this.identifierItemCase] +
                "']"
            )
            .classList.remove("v-data-table__selected");
          this.$setSelectedItems({
            id_query: this.id_query,
            id: this.dbitems[handleRowNumber][this.identifierItemCase],
            isSelected: false,
          });
        } else {
          this.$el
            .querySelector(
              "tr[itemid='" +
                this.dbitems[handleRowNumber][this.identifierItemCase] +
                "']"
            )
            .classList.add("v-data-table__selected");
          this.$setSelectedItems({
            id_query: this.id_query,
            id: this.dbitems[handleRowNumber][this.identifierItemCase],
            isSelected: true,
          });
        }
        this.dbitems[handleRowNumber].isSelected =
          !this.dbitems[handleRowNumber].isSelected;

        evt.stopPropagation();
      } else {
        this.selectedItemNumber = handleRowNumber;
        this.highlightRow(false);
      }
    },
    highlightRow(scrollToRow, direction = 0) {
      if (scrollToRow) {
        this.$refs.dummy.focus();
      }

      this.$nextTick(function () {
        this.$el
          .querySelectorAll("tr[itemid]")
          .forEach((el) =>
            el.classList.remove("primary", this.classnameforrow)
          );

        if (this.dbitems[this.selectedItemNumber]) {
          var nextValue =
            this.dbitems[this.selectedItemNumber][this.identifierItemCase];
          while (
            nextValue === "0" &&
            ((direction === 1 &&
              this.selectedItemNumber < this.dbitems.length - 1) ||
              (direction === -1 && this.selectedItemNumber > 0))
          ) {
            this.selectedItemNumber = this.selectedItemNumber + direction;
            nextValue =
              this.dbitems[this.selectedItemNumber][this.identifierItemCase];
          }
          var temprow = this.$el.querySelector(
            "tr[itemid='" + nextValue + "']"
          );
          if (temprow) {
            temprow.classList.add("primary", this.classnameforrow);

            if (scrollToRow) {
              temprow.scrollIntoView({
                behavior: "instant",
                block: "center",
              });
            }
          }
        }
        this.oldSelectedItemNumber = this.selectedItemNumber;
      });
    },
    openHistory(evttarget) {
      var lnk = evttarget.closest("tr").getAttribute("lnk");
      if (lnk && lnk !== "" && lnk.split("|").length == 3) {
        var editidentifier = lnk.split("|")[2];

        this.editlnk = {
          id_query: this.id_query,
          id_link: parseInt(lnk.split("|")[0]),
          id: parseInt(lnk.split("|")[1]),
          identifier: editidentifier,
        };
        this.historyVisible = true;
      }
    },
    mousemove() {
      if (this.msdwn === 1) {
        this.msmv = 1;
      }
    },

    mousedown() {
      this.msdwn = 1;
      this.msmv = 0;
    },

    refreshSettings(reload = true) {
      this.ShowHistoryCount = this.$getPref({
        TabName: "Options",
        PrefID: "ShowHistoryCount",
      });
      if (this.ShowHistoryCount == null) {
        this.ShowHistoryCount = false;
      }

      var temp = this.$getPref({
        TabName: "Options",
        PrefID: "ShowIdentifierColumn",
      });
      if (temp !== null && temp !== this.ShowIdentifierColumn) {
        this.ShowIdentifierColumn = temp;
        this.options["page"] = 1;
        this.options["sortBy"] = [];
        this.options["sortDesc"] = [];

        this.searchItem = {};
        if (reload) {
          this.getdata(true, true);
        }
      }
      if (this.ShowIdentifierColumn == null) {
        this.ShowIdentifierColumn = false;
      }

      if (typeof this.$el !== "undefined") {
        this.$el
          .querySelectorAll("tr." + this.classnameforrow + "[itemid]")
          .forEach((el) =>
            el.classList.remove("primary", this.classnameforrow)
          );
      }

      this.classnameforrow = this.$vuetify.theme.dark
        ? "darken-3"
        : "lighten-3";
    },
    initFilterRow() {
      this.showFilterHeader = false;
      var headerwidth = [];

      var headers = this.$refs.refmkstable.$el
        .getElementsByClassName("v-data-table-header")[0]
        .firstChild.getElementsByTagName("th");

      if (headers && headers.forEach) {
        headers.forEach(function (th) {
          var tstyle = window.getComputedStyle(th);
          headerwidth.push(
            parseInt(tstyle.width.replace("px", "")) -
              parseInt(tstyle.paddingLeft.replace("px", "")) -
              parseInt(tstyle.paddingRight.replace("px", ""))
          );
        });
      }

      this.showFilterHeader = !this.showFilterHeader;
      var tempthis = this;

      this.$nextTick(function () {
        // input boxen in Header Row einfügen - wegen fixed Header bei Scrolling
        var headerrow =
          this.$refs.refmkstable.$el.getElementsByClassName(
            "dmyfilterheader"
          )[0];
        if (headerrow) {
          var fheaders = headerrow.querySelectorAll("input,select");
          var i = 0;

          for (i = 0; i < headerwidth.length; i++) {
            if (fheaders[i]) {
              fheaders[i].style.width =
                (this.headers[i].value === this.identifier
                  ? 52
                  : headerwidth[i]) + "px";
            }
          }

          var headerRow = this.$refs.refmkstable.$el.getElementsByClassName(
            "v-data-table-header"
          );

          headerRow[0].appendChild(headerrow);

          Object.keys(tempthis.filter).forEach(function (key) {
            var indx = tempthis.headers.findIndex(
              (header) => header.value === key
            );
            if (indx > -1) {
              fheaders[indx].value = tempthis.filter[key];
            }
          });

          if (
            this.lastFocusedFilter !== null &&
            this.lastFocusedFilter >= 0 &&
            this.lastFocusedFilter < fheaders.length
          ) {
            fheaders[this.lastFocusedFilter].focus();
          }
        }
      });
    },
    // highlight: function (value, query) {
    //   if (value && query) {
    //     query = query.replace(/^([=<>!])|\*|%/gm, "");
    //     return value.replace(
    //       new RegExp(query, "ig"),
    //       (str) => `<span class='indigo lighten-4'>${str}</span>`
    //     );
    //   } else if (value) {
    //     return value;
    //   } else {
    //     return "";
    //   }
    // },
    highlight(value, query) {
      if (value && query && typeof value === "string") {
        if (value === query) {
          query = '"' + query + '"';
        } else if (query.substr(0, 1) === "=") {
          query = '"' + query.substr(1) + '"';
        }

        var replacements = [];
        var tempelem = null;

        query
          .replace(/\*|%/g, " ") //eslint-disable-line
          .match(/\"(.*?)\"|\S+/g) //eslint-disable-line
          .forEach(function (item) {
            // teilt string in Phrasen (getrennt durch Anführungszeichen) und einzelene Wörter

            (tempelem = item
              .replace(/\"/g, "") //eslint-disable-line
              .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")), //escape special regex chars
              "ig";
            replacements.push(tempelem);
          });

        var re = new RegExp(replacements.join("|"), "gi");
        var span = "<span class='indigo " + this.classnameforrow + "'>";
        return value.replace(re, (str) => `${span}${str}</span>`);
      } else if (value) {
        return value;
      } else {
        return "";
      }
    },
    emitClose() {
      //this.$changesHub.leaveHubGroup(this.grphsh);
      this.$emit("closeTable");
    },
    closeColumnsSelector(reload) {
      this.columnselectorvisible = false;
      if (reload) {
        this.queryChanged = true;
        this.getdata(false, true);
      }
    },
    closeHistory() {
      this.historyVisible = false;
    },
    refreshBaseHeight() {
      if (this.container.querySelector("div.col")) {
        this.container.querySelector("div.col").style.display = "none";
        this.baseheight = this.container.offsetHeight;
        this.container.querySelector("div.col").style.display = "block";
      } else {
        this.baseheight = this.container.offsetHeight;
      }
      this.updateTableHeight();
    },

    refreshdata() {
      this.options["page"] = 1;
      this.options["sortBy"] = [];
      this.options["sortDesc"] = [];

      this.searchItem = {};
      this.getdata();
    },

    rightalign(stype) {
      return stype === "Double" || stype === "Int32";
    },

    gettrstyle(item) {
      let tbgcolor = item["_bgcolor"];
      if (tbgcolor) {
        tbgcolor =
          "background-color: " +
          this.$getColor(this.$vuetify.theme.dark, tbgcolor) +
          " !important";
      } else {
        tbgcolor = "";
      }
      return tbgcolor;
    },
    lnkValue(mode, key, item) {
      key = key.toLowerCase();
      var lnk = null;
      if (mode === "td") {
        lnk = this.currentQueryLinksInfo.find(
          (lnk) => lnk.Feldname.toLowerCase() === key
        );
      } else {
        lnk = this.currentQueryLinksInfo.find(
          (lnk) => lnk.ID_Feldname.toLowerCase() === key
        );
      }
      if (lnk) {
        lnk.ID_Feldname = Object.keys(item).find(
          (key) => key.toLowerCase() === lnk.ID_Feldname.toLowerCase()
        );

        if (item[lnk.ID_Feldname] && item[lnk.ID_Feldname] !== "0") {
          if (lnk.ID_LinkType === "1") {
            return (
              lnk.ID_Link + "|" + item[lnk.ID_Feldname] + "|" + lnk.ID_Feldname
            );
          } else {
            return (
              "q|" +
              lnk.SQL_Edit_Table +
              "|" +
              item[lnk.ID_Feldname] +
              "|" +
              lnk.ID_Feldname
            );
          }
        }
      }
      return "";
    },

    getdata(scrollreset, forceload = false) {
      if (!forceload) {
        if (this.loading) {
          return;
        }

        this.$nextTick(function () {
          this.runningquery = JSON.stringify([
            this.options,
            this.id_query,
            this.searchItem,
            this.markedonly,
          ]);
        });

        if (
          !forceload &&
          this.runningquery ===
            JSON.stringify([this.options, this.id_query, this.searchItem])
        ) {
          return;
        }

        // if (this.runningquery) return
        this.runningquery = JSON.stringify([
          this.options,
          this.id_query,
          this.searchItem,
          this.markedonly,
        ]);

        if (this.id_query < 1) {
          return;
        }
      }

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      // const urlParams = new URLSearchParams(window.location.search);

      // var urlparams = this.$getUrlParams();

      // const l = parseInt(urlparams.l);
      // const v = parseInt(urlparams.v);

      // this.$setUrlParams(null);

      window.history.pushState("", document.title, "/");

      this.loading = true;

      var selectedItemsIds = this.$store.getters.getSelectedItems(
        this.id_query
      );

      var markedItemsIds = this.$store.getters.getMarkedItems(this.id_query);

      var tempthis = this;

      this.setMarkedItems();

      this.$http
        .post("api/data", {
          id_query: this.id_query,
          limit: itemsPerPage,
          page: page,
          sortBy: sortBy,
          sortDesc: sortDesc,
          search: this.searchItem,
          filter: this.idfilter,
          queryChanged: this.queryChanged,
          selectedItemsIds: this.markedonly ? selectedItemsIds : null,
          markedItemsIds: this.markFilterState !== 0 ? markedItemsIds : null,
          markedItemsIdsMode: this.markFilterState,
        })
        .then((response) => {
          if (scrollreset || this.queryChanged) {
            var elmnt = this.$refs.refmkstable.$el.getElementsByClassName(
              "v-data-table__wrapper"
            )[0];
            if (elmnt) {
              elmnt.scrollTop = 0;
              elmnt.scrollLeft = 0;
            }
          }

          this.queryChanged = false;

          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            this.tabletitle = "Error loading Data";
            this.myerror = response.data.error;
            this.loading = false;
          } else {
            this.dbitems = response.data.recordsets[0].data;

            this.actions = response.data.actions.data;

            this.queryrights = response.data.rights;
            this.filter = response.data.filter;

            tempthis.optionsChangedIgnore = true;
            this.options.sortBy = response.data.sortBy;
            this.options.sortDesc = response.data.sortDesc;
            window.setTimeout(function () {
              tempthis.optionsChangedIgnore = false;
            }, 500);

            this.searchItem = Object.assign({}, this.filter);
            this.hasInitialFilter = response.data.hasInitialFilter;
            this.grphsh = response.data.grphsh;
            this.legend =
              response.data.legend && response.data.legend.data
                ? response.data.legend.data
                : null;

            this.$changesHub.joinHubGroup(this.grphsh);

            this.tabletitle = "Loading";

            this.totalitems = parseInt(response.data.recordsets[1]);
            this.filtereditems = parseInt(response.data.recordsets[6]);
            this.identifier = response.data.recordsets[5];
            if (this.identifier !== null) {
              this.identifierItemCase = Object.keys(
                response.data.recordsets[0].columns.find(
                  (x) =>
                    Object.keys(x)[0].toLowerCase() ===
                    this.identifier.toLowerCase()
                )
              )[0];
            } else {
              this.identifier = "";
              this.identifierItemCase = "";
            }

            this.dbitems
              .filter((itemX) =>
                selectedItemsIds.includes(itemX[this.identifierItemCase])
              )
              .forEach((element) => (element.isSelected = true));

            let markedItems = this.$store.getters.getMarkedItems(this.id_query);

            markedItems.forEach((markedItem) => {
              const item = this.dbitems.find(
                (x) => x[this.identifierItemCase] === markedItem
              );
              if (item) {
                item.mark = true;
              }
            });

            this.headers = [];
            if (this.actions.length > 0) {
              this.headers.push({
                text: "Actions",
                value: "markable",
                sortable: false,
                rightalign: false,
              });

              this.dbitems.forEach((element) => {
                element.markable = true;
                element.marh = false;
              });
            }

            this.visibleProps = [];
            var tmpHeader = null;
            this.hasHistory = false;
            for (var cnt = 0; cnt < response.data.recordsets[2].length; cnt++) {
              if (response.data.recordsets[2][cnt] === "HID") {
                this.hasHistory = true;
              } else {
                tmpHeader = {
                  text: response.data.recordsets[2][cnt].replace(/_/g, " "),
                  value: response.data.recordsets[2][cnt],
                  type: response.data.recordsets[0].columns[cnt][
                    response.data.recordsets[2][cnt]
                  ],
                  rightalign: this.rightalign(
                    response.data.recordsets[0].columns[cnt][
                      response.data.recordsets[2][cnt]
                    ]
                  ),
                  isphonenr:
                    response.data.recordsets[2][cnt].indexOf("ufnummer") > -1 ||
                    response.data.recordsets[2][cnt] === "Telefonnummer",
                };

                if (
                  response.data.recordsets[2][cnt]
                    .substr(0, 3)
                    .toLowerCase() === "id_"
                ) {
                  this.linkProps.push(tmpHeader.text);
                  if (this.ShowIdentifierColumn) {
                    this.headers.push(tmpHeader);
                    this.visibleProps.push(tmpHeader);
                  }
                } else if (
                  tmpHeader.value !== "_bgcolor" &&
                  tmpHeader.value !== "markable"
                ) {
                  this.headers.push(tmpHeader);
                  this.visibleProps.push(tmpHeader);
                }
              }
            }

            if (this.hasHistory) {
              this.headers.unshift({
                text: "",
                value: "history",
                rightalign: false,
                sortable: false,
              });
            }

            if (this.headers.length > 0) {
              this.headers[0].fixed = true;
            }

            this.$refs.refmkstable.$el
              .querySelectorAll("input.mksfilter")
              .forEach((el) => {
                el.style.backgroundColor = "";
              });

            var filterErrors = response.data.filterErrors;
            Object.keys(filterErrors).forEach((el) => {
              var filterErrorIndex = tempthis.headers.findIndex(
                (x) => x.value.toLowerCase() === el
              );
              if (filterErrorIndex > -1) {
                tempthis.$refs[
                  "s_header_" + filterErrorIndex
                ][0].style.backgroundColor = "orange";
              }
            });

            // xmpp
            var Name = tempthis.visibleProps.find(
              (x) =>
                x.value.toLowerCase() === "name" ||
                x.value.toLowerCase() === "nachname" ||
                x.value.toLowerCase() === "surname"
            );
            var ADS_User = tempthis.visibleProps.find(
              (x) =>
                x.value.toLowerCase() === this.accountFieldName.toLowerCase()
            );
            if (Name && ADS_User) {
              Name.xmpp = true;
            }

            this.currentQueryInfo = response.data.recordsets[3].data[0];
            this.currentQueryLinksInfo = response.data.recordsets[4].data;

            this.tabletitle =
              (this.currentQueryInfo.QueryKat !== "Hidden_from_Nav"
                ? this.currentQueryInfo.QueryKat + " / "
                : "") + this.currentQueryInfo.Bezeichnung;

            window.setTimeout(this.tableHeighthRefresh(), 600);
            this.loading = false;
          }
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          this.errors.push(e);
          this.loading = false;
        });
    },

    tableHeighthRefresh() {
      this.updateTableHeight();
      this.ignoreKeyStrokes = false;
      this.initFilterRow();
      this.highlightRow();

      var urlParams = this.$getUrlParams();

      if (urlParams !== null && !isNaN(urlParams.l) && !isNaN(urlParams.v)) {
        this.openEditDialog(
          urlParams.l +
            "|" +
            urlParams.v +
            "|" +
            this.identifier +
            "|" +
            urlParams.f
        );
        this.$setUrlParams(null);
      }
    },
    getitemid(evttarget) {
      var row = evttarget.target.closest("tr");

      return parseInt(row.getAttribute("itemid"));
    },

    getitemindex(evttarget) {
      var row = evttarget.target.closest("tr");
      return row && row.rowIndex ? row.rowIndex - 1 : -10;
    },

    getitemByIndex(rowindex) {
      return this.dbitems[rowindex];
    },

    getitembyid(itemid, propname = "") {
      let tempidentifier = propname !== "" ? propname : this.identifierItemCase;
      itemid = parseInt(itemid);

      return this.dbitems.find(function (item) {
        return item[tempidentifier] === itemid;
      });
    },

    getItemIndexFromId(itemid, propname = "") {
      let tempidentifier = propname !== "" ? propname : this.identifierItemCase;
      itemid = parseInt(itemid);

      return this.dbitems.findIndex(function (item) {
        return item[tempidentifier] === itemid;
      });
    },

    itemcommand(evttarget, command) {
      // var itemid = this.getitemid(evttarget)
      // var item = this.getitembyid(itemid)
      var itemindex = this.getitemindex(evttarget);
      if (itemindex !== -10) {
        var item = this.getitemByIndex(itemindex);
        switch (command) {
          case "edit":
            this.editItem(item);
            break;
        }
      }
    },
    openEditDialog(lnk) {
      if (lnk && lnk !== "") {
        var lnkparts = lnk.split("|");
        var editidentifier = "";
        if (
          lnkparts.length === 3 ||
          (lnkparts.length === 4 && lnkparts[3] !== "id_newmaprocess")
        ) {
          editidentifier = lnkparts[2];

          this.editlnk = {
            id_query: this.id_query,
            id_link: parseInt(lnkparts[0]),
            id: parseInt(lnkparts[1]),
            identifier: editidentifier,
            tablename: this.currentQueryLinksInfo
              .find((x) => x.ID_Link == lnkparts[0])
              .SQL_Edit_Table.replace("Tab_", "")
              .replace("Opt_", "")
              .replaceAll("_", " "),
            hasPrevious: this.hasPrevious,
            hasNext: this.hasNext,
          };
          // if (editidentifier.toLowerCase() === "id_resource_bundle") {
          //   this.resourcebundle = true;
          // }
          // else {
          this.details = true;
          // }
        } else if (lnkparts.length === 5) {
          editidentifier = lnkparts[2];

          this.editlnk = {
            id_query: this.id_query,
            id_link: parseInt(lnkparts[0]),
            id: null,
            identifier: editidentifier,
            search: lnkparts[3] + "=" + lnkparts[4],
            tablename: this.currentQueryLinksInfo
              .find((x) => x.ID_Link == lnkparts[0])
              .SQL_Edit_Table.replace("Tab_", "")
              .replace("Opt_", "")
              .replaceAll("_", " "),
            hasPrevious: this.hasPrevious,
            hasNext: this.hasNext,
          };
          this.details = true;
        } else if (lnkparts.length === 4) {
          if (lnkparts[3] === "id_newmaprocess") {
            window.open(
              "https://homedb-old.laboratories.telekom.de/Daten/ChecklistMA.aspx?Query=226&ID_NewMAProcess=" +
                parseInt(lnkparts[2])
            );
          } else {
            window.open(
              "?q=" +
                parseInt(lnkparts[1]) +
                "&f=" +
                lnkparts[3] +
                "&v=" +
                lnkparts[2]
            );
          }
          return;
        }
      }
    },
    handleClick(tdevent, useRowAttribute = false) {
      if (this.msdwn === 1 && this.msmv === 1) {
        this.msdwn = 0;
        this.msmv = 0;
        return;
      }

      this.msdwn = 0;
      this.msmv = 0;

      if (!tdevent.ctrlKey) {
        this.selectRow(tdevent);
        if (tdevent.target.tagName !== "I") {
          var lnk = "";
          if (useRowAttribute) {
            lnk = tdevent.target.closest("tr").getAttribute("lnk");
          } else {
            lnk = tdevent.target.closest("td").getAttribute("lnk");
          }
          if (lnk && lnk !== null && lnk !== "") {
            this.openEditDialog(lnk);
          }
        }
      }
    },

    handleDblClick(evt) {
      this.handleClick(evt, true);
    },

    openColumnSettings() {
      this.show;
    },
    openNewItemDialog() {
      this.openEditDialog(
        this.currentQueryLinksInfo[0].ID_Link + "|0|" + this.identifier
      );
    },

    deleteItem(item) {
      const index = this.dbitems.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
        this.dbitems.splice(index, 1);
    },
    closeDetails: function (result) {
      this.details = false;
      if (typeof result === "string" && result !== "") {
        this.$showMessage({ content: result, color: "info" });
      }
      this.highlightRow(this.selectedItemNumber);

      // this.getdata(false, true);
    },

    closeResourcebundle: function () {
      this.resourcebundle = false;

      this.highlightRow(this.selectedItemNumber);
    },

    navigate(previous) {
      this.selectedItemNumber = this.dbitems.findIndex(
        (p) => p[this.identifierItemCase] == this.editlnk.id
      );
      this.selectedItemNumber += previous ? -1 : 1;

      if (this.dbitems[this.selectedItemNumber]) {
        this.openEditDialog(
          this.lnkValue(
            "tr",
            this.identifierItemCase,
            this.dbitems[this.selectedItemNumber]
          )
        );
      }
    },
    reopen() {
      this.editlnk.id_query = 0;
      this.details = false;

      this.$nextTick(() => {
        this.details = false;

        this.$nextTick(() => {
          this.selectedItemNumber = this.dbitems.findIndex(
            (p) => p[this.identifierItemCase] == this.editlnk.id
          );

          if (this.dbitems[this.selectedItemNumber]) {
            this.editlnk.id_query = this.id_query;
            this.openEditDialog(
              this.lnkValue(
                "tr",
                this.identifierItemCase,
                this.dbitems[this.selectedItemNumber]
              )
            );
          }
        });
      });
    },
    start() {
      this.lastFocusedFilter = document.activeElement.getAttribute("fi")
        ? parseInt(document.activeElement.getAttribute("fi"))
        : -1;
    },

    updateTableHeight() {
      if (this.baseheight) {
        var header = this.container.querySelector("header.v-toolbar");
        var footer = this.container.querySelector("div.v-data-footer");
        if (header !== null && footer !== null) {
          this.tableheight =
            this.baseheight - 48 - header.offsetHeight - footer.offsetHeight;
        }
      }
    },

    Changes(params) {
      if (params["params"] && params["params"].grpId === this.grphsh) {
        switch (params["params"].what) {
          case "list item changed":
            // is item visible ?
            if (
              this.getitembyid(
                params["params"].content,
                params["params"].propname
              )
            ) {
              this.ignoreKeyStrokes = true;
              this.getdata(false, true);
            }
            break;
          case "list item added":
            this.getdata(false, true);
            break;
        }
      }
    },

    exportXlsx(completeList) {
      document.body.classList.add("busy-cursor");
      var selectedItemsIds = this.$store.getters.getSelectedItems(
        this.id_query
      );

      this.$http
        .post("api/getExportList", {
          id_query: this.id_query,
          limit: 0,
          page: 0,
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          search: this.searchItem,
          filter: this.idfilter,
          selectedItemsIds: this.markedonly ? selectedItemsIds : null,
          completeList: completeList,
        })
        .then((response) => {
          if (response.data !== "") {
            let fileName = decodeURI(
              response.headers["content-disposition"]
                .split("filename=")[1]
                .split(";")[0]
            );
            if (fileName.substr(0, 1) == '"') {
              fileName = fileName.substr(1, fileName.length - 2);
            }
            var tempthis = this;
            var req = new XMLHttpRequest();
            req.open("GET", "data:application/octet;base64," + response.data);
            req.responseType = "arraybuffer";
            req.onload = function fileLoaded(e) {
              var byteArray = new Int8Array(e.target.response);
              tempthis.downloadFile(
                byteArray,
                fileName,
                response.headers["content-type"]
              );
            };
            req.send();
          }
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          document.body.classList.remove("busy-cursor");
        });

      this.$nextTick(() => {
        this.selectedExport = null;
      });
    },

    downloadFile(data, fileName, type = "text/plain") {
      // Create an invisible A element
      const a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);

      // Set the HREF to a Blob representation of the data to be downloaded
      a.href = window.URL.createObjectURL(new Blob([data], { type }));

      // Use download attribute to set set desired file name
      a.setAttribute("download", fileName);

      // Trigger the download by simulating click
      a.click();

      // Cleanup
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
      document.body.classList.remove("busy-cursor");
    },

    jumpToFilter(jumpto) {
      this.stillFocusingFastJump = true;
      if (jumpto && jumpto.n) {
        var idx = this.headers.findIndex((h) => h.value === jumpto.n);
        if (idx > -1) {
          this.lastFocusedFilter = idx;
          this.focusFilter(idx);
        }
      }

      this.showFastJumpDialog = false;
    },
  },
};
</script>
